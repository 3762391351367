import React, { useState, ReactNode } from 'react';
import { Popup, StrictPopupProps } from 'semantic-ui-react';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';

import SearchBar from '@common/components/search-bar';
import Api from '@common/services/api';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';

let gf: GiphyFetch | undefined;

const getGf = () => {
  if (!gf) gf = new GiphyFetch('1lG2TArRBuFM98NrTON6vW2nJt29G2lw');
  return gf;
};

export type GifInputOwnProps = {
  children: ReactNode;
  disabled?: boolean;
  position?: StrictPopupProps['position'];
  onChange: (data: any) => void;
  onBlur?: any;
};

export const GifInput = ({
  children,
  onChange,
  onBlur,
  disabled,
  position = 'top left'
}: GifInputOwnProps) => {
  const organisationId = useAppSelector(selected).id;
  const [search, setSearch] = useState(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <Popup
      className="Form__GifInput"
      open={isOpen}
      on="click"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position={position}
      header={(
        <Popup.Header>
          {/* @ts-expect-error */}
          <SearchBar onSearch={setSearch} />
        </Popup.Header>
      )}
      content={(
        <Grid
          key={search}
          width={350}
          columns={3}
          fetchGifs={(offset) => (search
            ? getGf().search(search, { sort: 'relevant', offset, limit: 10 })
            : getGf().trending({ offset, limit: 10 })
          )}
          onGifClick={async (giphy, e) => {
            e.preventDefault();

            const { data } = await Api.post(`/v2/organisations/${organisationId}/files/url`, {
              url: giphy.images.original.url,
              is_document: false,
              file_type: 'gif',
            });

            setOpen(false);

            onChange(data);
          }}
        />
      )}
      trigger={(
        <div onBlur={onBlur} className={`Form__FileInput${disabled ? ' Form__FileInput--disabled' : ''}`}>
          {children}
        </div>
      )}
    />
  );
};
