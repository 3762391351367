import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { omit } from 'lodash';

import { GifInput, GifInputOwnProps } from './gif-input';

const ReduxFormField = (props: WrappedFieldProps & GifInputOwnProps) => {
  return (
    <GifInput
      {...props.input}
      {...omit(props, ['input', 'meta'])}
    />
  );
};

export default ({ name, ...props }: BaseFieldProps & Omit<GifInputOwnProps, 'onChange'>) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);
