// list of image mime types that are deemed important by this article
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// minus 'webp', 'svg' and 'vnd.microsoft.icon' formats

export const IMAGES_ACCEPT = [
  'avif',
  'bmp',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'tiff'
].map((format: string) => `image/${format}`).join(', ');

export const ALL_ACCEPT = [
  'application/pdf',
  '.mp3', '.wav', '.aac',
  '.doc', '.docx', '.docm', '.dotx', '.dotm', '.pages', '.txt',
  '.csv', '.xls', '.xlsx', '.xlsm', '.xltm', '.numbers',
  '.ppt', '.pptx', '.pps', '.ppsx', '.pptm', '.potx', '.potm', '.key', '.odp',
].join(',');
